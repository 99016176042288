.continueButton {
  width: 200px
}

.dropShadow {
  filter: drop-shadow(0 0.3rem 0.3rem black);
}

.dropShadowBtn {
  filter: drop-shadow(0 0.1rem 0.1rem black);
}

.alertText {
  font-weight: 500;
}

.text-box-center {
  display: flex; 
  align-items: center;
  height: 100%;
}

.fade {
  -webkit-transition-duration: 1.8s; /* Safari */
  transition-duration: 1.8s;
}

#alertFade {
  -webkit-transition-duration: .5s !important; /* Safari */
  transition-duration: .5s !important;
}

.btn-secondary {
  background-color: #6181b1;
}